.main{
    font-family: ;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login-container{
    border: 2px rgb(184, 183, 183) solid;
    border-radius: 5px;
    background-color: #ffffff;
    padding: 2% 0;
    width: 30%;
    background-color: white;
    display: flex;
    flex-direction: column;
}
h1{
    margin: 1% 0;
    padding: 0;
}
form{
    padding: 3% 3%;
    margin: 0 3%;
    display: flex;
    flex-direction: column;
}
input{
    background-color: #f2f2f2;
    border: 2px rgb(184, 183, 183) solid;
    border-radius: 3px;
    font-size: 16px;
    padding: 3% 3%;
    margin-top: 6%;
}
::-ms-reveal {
    opacity: .2;
  }
input:focus , input:hover{
    outline-color: rgb(130, 128, 128);
}
.success{
    background-color: #3689f8;
    color: white;
    margin: 0;
    padding: 2% 0;
    margin: 0 3%;
}
.submitButton{
    font-size: 16px;
    font-weight: 550;
    padding: 2.5% 2%;
    color: white;
    background-color: #409649;
    border: none;
    margin: 3% 0;
    margin-top: 6%;
}
p{
    margin-top: 1% ;
    margin-bottom: 0;
    text-align: left;
    font-size: 13px;
    color: red;
}